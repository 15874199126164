<template>
  <div class="mx-auto my-3" max-width="100%">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <h4 class="grey--text ml-5">Upload Technical Documents</h4>
    <v-card-text>
      <v-row align="center">
        <v-col
          v-for="(item, i) in tender.technical_documents"
          :key="i"
          cols="12"
          sm="4"
          class="mt-n2"
        >
          <v-tooltip top v-if="item.attachment">
            <template v-slot:activator="{ on, attrs }">
              <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                <a
                  :href="item.attachment"
                  target="_blank"
                  class="d-block primary--text text--darken-1 text-decoration-none"
                >
                  <v-icon x-large color="primary">mdi-file-word</v-icon>
                </a>
              </span>
            </template>
            <span
              >Click to download sample : {{ truncate(item.name, 30) }}</span
            >
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                <a
                  href="3"
                  target="_blank"
                  class="d-block primary--text text--darken-1 text-decoration-none"
                >
                  <v-icon x-large color="grey">mdi-file-alert</v-icon>
                </a>
              </span>
            </template>
            <span>No sample document</span>
          </v-tooltip>

          <v-tooltip
            top
            v-if="
              documentUploaded(
                applicant_uploaded_technical_docs,
                'document_id',
                item.id
              )
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                <a
                  :href="
                    get_item_url(applicant_uploaded_technical_docs, item.id)
                  "
                  target="_blank"
                  class="d-block success--text text--darken-1 text-decoration-none"
                >
                  <v-icon x-large color="success"
                    >mdi-arrow-down-bold-circle</v-icon
                  >
                </a>
              </span>
            </template>
            <span
              >Click to download your uploaded :
              {{ truncate(item.name, 30) }}</span
            >
          </v-tooltip>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                <a
                  target="_blank"
                  class="d-block success--text text--darken-1 text-decoration-none"
                >
                  <v-icon x-large color="grey"
                    >mdi-arrow-down-bold-circle</v-icon
                  >
                </a>
              </span>
            </template>
            <span>Not yet uploaded</span>
          </v-tooltip>
          <v-file-input
            show-size
            :label="item.name"
            outlined
            dense
            append-icon="mdi-file-upload"
            prepend-icon=""
            color="grey"
            class="mb-n5"
            v-model="files['file'+item.id]"
            @change="handleFileUpload(files['file'+item.id], item, tender)"
          >
            <template v-slot:selection="{ text }">
              <v-chip label color="success darken-1" small>
                {{ text }}
              </v-chip>
            </template>

            <template
              v-slot:label
              v-if="
                documentUploaded(
                  applicant_uploaded_technical_docs,
                  'document_id',
                  item.id
                )
              "
            >
              <span class="input__label success--text">
                <v-icon color="success" class="mt-n2">mdi-check-all</v-icon>
                {{ item.name }}</span
              >
            </template>
          </v-file-input>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import TenderService from "../services/tender.service";

export default {
  name: "TechnicalDocument",
  data: () => ({
    selection: 1,
      files:{},
  }),
  created() {
    this.getApplicantUploadedTechnicalDocs();
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    tender() {
      return this.$store.state.tender.selected;
    },
    applicant_uploaded_technical_docs: {
      get: function () {
        return this.$store.state.tender.applicant_uploaded_technical_docs;
      },
      set: function(value) {
        this.$store.dispatch("tender/setApplicantUploadedTechnicalDocs", value);
      },
    },
  },
  methods: {
    getApplicantUploadedTechnicalDocs() {
      return TenderService.getApplicantUploadedTechnicalDocs(
        this.tender.id,
        this.user.id
      ).then(
        (response) => {
          if (response.status == 200) {
            this.applicant_uploaded_technical_docs = response.data.data;
            this.$store.dispatch(
              "tender/setApplicantUploadedTechnicalDocs",
              response.data.data
            );
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    reserve() {},
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    arraySearch(array, key, value) {
      let filteredArray = [];
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          filteredArray = array[i];
        }
      }
      return filteredArray;
    },
    documentUploaded(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          return true;
        }
      }
      return false;
    },
    get_item_url(array, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i]["document_id"] == value) {
          return array[i]["content"];
        }
      }
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file, item, tender) {
      console.log("uploading...");
      let formData = {};
      formData["tender_id"] = tender.id;
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = item.id;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      TenderService.addApplicantTechnicalDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Document uploaded!");
            this.getApplicantUploadedTechnicalDocs();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            console.log(error.response.data.errors);
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
  },
};
</script>
