<template>
  <div class="text-left">
          <h3 class="my-5 error--text text--darken-1">TERMS AND CONDITIONS FOR TENDERING</h3>
          <p>
            1. Tenders comprising Form of Tender, Pricing Schedule and
            supporting information must be submitted, together with a covering
            letter signed by an authorised representative of the Supplier, no
            later than the date shown in the covering letter. The minimum number
            of Tenders should be one bound and one loose, all on A4. This
            Company ('the Company') may reject any tender which is late, does
            not fully comply with the stipulated requirements.
          </p>
          <p>
            2. The Company reserves the right to accept any tender, in whole or
            in part, or to negotiate further with one or more Tenderer. The
            Tenderer(s) selected will be chosen on the basis of best value for
            money. This means suitable quality, delivery, level of risk and
            reaction to customer needs at best price.
          </p>
          <p>
            3. All marketing or similar activities by the Tenderer associated
            with the Tender must cease upon submission of the Tender and only
            resume following notification from the Company of the outcome of the
            tender competition.
          </p>
          <p>
            4. No amendments to the Tender will be permitted after submission
            unless requested by the Company. Prices and rates quoted within the
            Tender should be on a fixed basis for the contract period and all
            tendered prices must be exclusive of Value Added Tax. They should
            remain fixed for ninety days from tender close date.
          </p>
          <p>
            5. Unsuccessful tenderers will be notified after contract award.
            incurred in relation to this tender are to be borne by the Tenderer.
          </p>
          <p>
            6. Any material of a confidential nature submitted by a Tenderer
            should be clearly marked. All material provided by the Company must
            be regarded as confidential.
          </p>
          <p>
            7. The Company's terms and conditions are attached and will be used
            for any contract with the Tenderer.
          </p>
          <p>
            8. The Company welcomes innovation from Tenderers which will reduce
            whole life cycle costs and/or improve quality. Any such proposals
            should be set out on a separate sheet at the back of the tender and
            referred to in the covering letter.
          </p>
          <p>
            9. The Company may require you to nominate up to three client
            reference sites and would appreciate if these are made ready.
          </p>
          <p>10. All correspondence must be in English. All costs</p>
          <p>
            11. Where this Invitation to Tender includes reference to goods of a
            specific make or source or a particular process, or indicates trade
            marks, patents, types, or specific origins or production, the
            Company may be prepared to accept an equivalent. It is the
            responsibility of the Tenderer to demonstrate that the alternative
            product is suitable for the task.
          </p>
          <p>
            12. The Company has a strong belief in propriety and ethics. A
            Tenderer attempting to offer an inducement to any member of staff is
            likely to be debarred.
          </p>

        <v-divider></v-divider>

  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
};
</script>