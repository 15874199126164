var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-auto my-3",attrs:{"max-width":"100%"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('h4',{staticClass:"grey--text ml-5"},[_vm._v("Upload Technical Documents")]),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.tender.technical_documents),function(item,i){return _c('v-col',{key:i,staticClass:"mt-n2",attrs:{"cols":"12","sm":"4"}},[(item.attachment)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block mb-1"},'span',attrs,false),on),[_c('a',{staticClass:"d-block primary--text text--darken-1 text-decoration-none",attrs:{"href":item.attachment,"target":"_blank"}},[_c('v-icon',{attrs:{"x-large":"","color":"primary"}},[_vm._v("mdi-file-word")])],1)])]}}],null,true)},[_c('span',[_vm._v("Click to download sample : "+_vm._s(_vm.truncate(item.name, 30)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block mb-1"},'span',attrs,false),on),[_c('a',{staticClass:"d-block primary--text text--darken-1 text-decoration-none",attrs:{"href":"3","target":"_blank"}},[_c('v-icon',{attrs:{"x-large":"","color":"grey"}},[_vm._v("mdi-file-alert")])],1)])]}}],null,true)},[_c('span',[_vm._v("No sample document")])]),(
            _vm.documentUploaded(
              _vm.applicant_uploaded_technical_docs,
              'document_id',
              item.id
            )
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block mb-1"},'span',attrs,false),on),[_c('a',{staticClass:"d-block success--text text--darken-1 text-decoration-none",attrs:{"href":_vm.get_item_url(_vm.applicant_uploaded_technical_docs, item.id),"target":"_blank"}},[_c('v-icon',{attrs:{"x-large":"","color":"success"}},[_vm._v("mdi-arrow-down-bold-circle")])],1)])]}}],null,true)},[_c('span',[_vm._v("Click to download your uploaded : "+_vm._s(_vm.truncate(item.name, 30)))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block mb-1"},'span',attrs,false),on),[_c('a',{staticClass:"d-block success--text text--darken-1 text-decoration-none",attrs:{"target":"_blank"}},[_c('v-icon',{attrs:{"x-large":"","color":"grey"}},[_vm._v("mdi-arrow-down-bold-circle")])],1)])]}}],null,true)},[_c('span',[_vm._v("Not yet uploaded")])]),_c('v-file-input',{staticClass:"mb-n5",attrs:{"show-size":"","label":item.name,"outlined":"","dense":"","append-icon":"mdi-file-upload","prepend-icon":"","color":"grey"},on:{"change":function($event){return _vm.handleFileUpload(_vm.files['file'+item.id], item, _vm.tender)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var text = ref.text;
return [_c('v-chip',{attrs:{"label":"","color":"success darken-1","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}},(
              _vm.documentUploaded(
                _vm.applicant_uploaded_technical_docs,
                'document_id',
                item.id
              )
            )?{key:"label",fn:function(){return [_c('span',{staticClass:"input__label success--text"},[_c('v-icon',{staticClass:"mt-n2",attrs:{"color":"success"}},[_vm._v("mdi-check-all")]),_vm._v(" "+_vm._s(item.name))],1)]},proxy:true}:null],null,true),model:{value:(_vm.files['file'+item.id]),callback:function ($$v) {_vm.$set(_vm.files, 'file'+item.id, $$v)},expression:"files['file'+item.id]"}})],1)}),1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }